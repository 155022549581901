<template>
  <a-drawer
    :title="$t(title)"
    :width="isMobile?'375px':width"
    :body-style="{ overflowY: 'auto', paddingBottom: '80px', ...bodyStyle }"
    :visible.sync="value"
    @close="onClose"
  >
    <a-spin :spinning.sync="loading">
      <div v-if="$slots.title" slot="title">
        <slot name="title"></slot>
      </div>
      <slot></slot>
      <!-- <template v-if="$slots.footer">
        <slot name="footer"></slot>
      </template> -->
      <div
        :style="{
          position: 'fixed',
          right: 0,
          bottom: 0,
          width: width,
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >

        <a-button v-if="cancelText" :style="{ marginRight: '8px' }" @click="onClose">
          {{ $t(cancelText) }}
        </a-button>
        <a-button v-if="confirmText" type="primary" @click="$emit('confirm')">
          {{ $t(confirmText) }}
        </a-button>
        <slot name="footer"></slot>

      </div>
    </a-spin>

  </a-drawer>
</template>
<script>
import { deviceMixin } from '@/store/device-mixin'
export default {
  mixins: [deviceMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '提示'
    },
    width: {
      type: String,
      default: '30%'
    },
    bodyStyle: {
      type: Object,
      default: () => {}
    },
    confirmText: {
      type: [String, Boolean],
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    onClose () {
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="less" scoped></style>
