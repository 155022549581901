import request from '@/utils/request'


// 调整代理商
export function putObj(data) {
  return request({
    url: '/system/user/tz',
    method: 'put',
    data
  })
}

// 公司列表
export function fetchList(userType) {
  return request({
    url: '/system/user/list',
    method: 'get',
    params: {
      userType
    }
  })
}


