<template>
  <cy-drawer
    :title="$t('调整公司')"
    v-model="visible"
    @cancel="() => $emit('close')"
    @confirm="preservation('form')"
  >
    <a-spin :spinning="loading">
      <a-form-model :model="form" labelAlign="left" :rules="rules" ref="form">
        <a-form-model-item label="操作类型" prop="type">
          <a-radio-group v-model="form.type">
            <a-radio :value="0">绑定</a-radio>
            <a-radio :value="1">解绑</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="form.type == 0" :label="$t('所属公司')" prop="fid">
          <a-select v-model="form.fid" placeholder="请选择" allowClear>
            <a-select-option v-for="item in gongsiList" :key="item.id" :value="item.id">{{item.nickName}}-{{item.phoneNumber}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </cy-drawer>
  <!-- <a-drawer
    :title="$t('添加/编辑物流箱')"
    width="50vw"
    :visible="visible"
    :confirmLoading="loading"
    :body-style="{ maxHeight: '67vh', overflowY: 'auto' }"
    @ok="preservation('form')"
    @cancel="() => $emit('close')"
  >

  </a-drawer> -->
</template>
<script>
import CyDrawer from '@/components/CyDarwer'
import { fetchList, putObj } from '../api'


export default {
  // 组件名称
  name: 'CreateForm',
  // 过滤器
  filters: {
  },
  // 组件
  components: {
    CyDrawer
  },
  // props类型
  props: {
    model: {
      type: Object,
      default: () => null
    }
  },
  // 数据
  data () {
    return {
      visible: false,
      loading: false,
      form: {
        "fid": 0,
        "id": 0,
        "type": 0
      },
      rules: {
        fid: [
          { required: true, message: this.$t('请选择') }
        ],
        type: [
          { required: true, message: this.$t('请选择') }
        ],
        
      },
      gongsiList: [] // 公司列表
    }
  },
  // 计算属性
  computed: {
  },
  // 计算属性监听
  watch: {
  },
  // 创建时
  created () {
  },
  // 挂载完毕
  mounted () {
  },
  // 方法
  methods: {
    // 获取公司列表
    async getGongsiList() {
      const res = await fetchList(5)
      console.log(res, '130>>>>>');
      this.gongsiList = res.data
      
    },
    init (row) {

      
      if (row) {
        this.form = {
          id: row.id,
          fid: row.fid,
          type: 1
        }
      }
      this.getGongsiList()
      this.visible = true
      
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    preservation (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.form.id) {
            putObj({ ...this.form }).then(res => {
              this.visible = true
              this.$emit('refresh')
              this.$emit('close')
            }).catch(() => {
              this.loading = false
            })
          } else {
            addObj({ ...this.form }).then(res => {
              this.visible = true
              this.$emit('refresh')
              this.$emit('close')
            }).catch(() => {
              this.loading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
</style>
