<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-row :gutter="24">
        <a-col v-if="false" :span="4">
          <!-- 部门树 -->
          <dept-tree ref="deptTree" :deptOptions="deptOptions" @select="clickDeptNode" />
        </a-col>
        <a-col :span="24">
          <!-- 条件搜索 -->
          <div class="table-page-search-wrapper">
            <a-form :layout="layoutValue">
              <a-row :gutter="48">
                <a-col :md="8" :sm="24">
                  <a-form-item :label="$t('用户名称')">
                    <a-input v-model="queryParam.userName" :placeholder="$t('请输入')" allow-clear />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item :label="$t('手机号')">
                    <a-input v-model="queryParam.phoneNumber" :placeholder="$t('请输入')" allow-clear />
                  </a-form-item>
                </a-col>
                <template v-if="advanced">
                  <a-col :md="8" :sm="24">
                    <a-form-item :label="$t('状态')" prop="status">
                      <a-select v-model="queryParam.status" :placeholder="$t('请选择状态')" style="width: 100%" allow-clear>
                        <a-select-option
                          v-for="(d, index) in dict.type['sys_normal_disable']"
                          :key="index"
                          :value="d.value">{{ d.label }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item :label="$t('创建时间')">
                      <a-range-picker
                        style="width: 100%"
                        v-model="dateRange"
                        valueFormat="YYYY-MM-DD"
                        format="YYYY-MM-DD"
                        allow-clear />
                    </a-form-item>
                  </a-col>
                </template>
                <a-col :md="!advanced && 8 || 24" :sm="24">
                  <span
                    class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                    <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('查询') }}</a-button>
                    <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{ $t('重置') }}</a-button>
                    <a @click="toggleAdvanced" style="margin-left: 8px">
                      {{ advanced ? $t('收起') : $t('展开') }}
                      <a-icon :type="advanced ? 'up' : 'down'" />
                    </a>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 操作 -->
          <div class="table-operations">
            <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['system:user:add']">
              <a-icon type="plus" />{{ $t('新增') }}
            </a-button>
            <a-button
              type="primary"
              :disabled="single"
              @click="$refs.createForm.handleUpdate(undefined, ids)"
              v-hasPermi="['system:user:edit']">
              <a-icon type="edit" />{{ $t('修改') }}
            </a-button>
            <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['system:user:remove']">
              <a-icon type="delete" />{{ $t('删除') }}
            </a-button>
            <!-- <a-button
              type="dashed"
              @click="$refs.importExcel.importExcelHandleOpen()"
              v-hasPermi="['system:user:import']">
              <a-icon type="import" />导入
            </a-button>
            <a-button type="primary" @click="handleExport" v-hasPermi="['system:user:export']">
              <a-icon type="download" />{{ $t('导出') }}
            </a-button> -->
            <table-setting
              :style="{ float: 'right' }"
              :table-size.sync="tableSize"
              v-model="columns"
              :refresh-loading="loading"
              @refresh="getList" />
          </div>
          <!-- 增加修改 -->
          <!-- 创建/编辑用户,单独封装了组件 -->
          <create-form
            ref="createForm"
            :deptOptions="deptOptions"
            :statusOptions="dict.type['sys_normal_disable']"
            :sexOptions="dict.type['sys_user_sex']"
            @ok="getList"
            @select-tree="getTreeselect" />
          <!-- 调整公司 -->
          <tz-create-form
            v-if="tzVisible"
            ref="tzForm"
            @close="tzVisible = false"
            @refresh="getList"
          ></tz-create-form>
          <!-- 修改密码抽屉 -->
          <reset-password ref="resetPassword" />
          <!-- 分配角色模态框 -->
          <auth-role ref="authRole" />
          <!-- 上传文件 -->
          <import-excel ref="importExcel" @ok="getList" />
          <!-- 数据展示 -->
          <a-table
            :loading="loading"
            :scroll="{ y: scrollY+64-58}"
            :size="tableSize"
            rowKey="id"
            :columns="columns"
            :data-source="list"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="false"
            :bordered="tableBordered">
            <!-- tips: 此处的status 其实是后端的enable字段，所以status '0' 代表的是开启  status '1' 代表的是关闭 -->
            <span slot="status" slot-scope="text, record">
              <a-popconfirm
                :ok-text="$t('是')"
                :cancel-text="$t('否')"
                @confirm="confirmHandleStatus(record)"
                @cancel="cancelHandleStatus(record)">
                <span slot="title">{{ $t('确认') }}<b>{{ record.status === 0 ? $t('启用') : $t('停用') }}</b>{{ record.nickName }}{{ $t('的用户吗?') }}</span>
                <a-switch :checked-children="$t('开')" :un-checked-children="$t('关')" :checked="record.status === 1 " />
              </a-popconfirm>
            </span>
            <span slot="createTime" slot-scope="text, record">
              {{ parseTime(record.createTime) }}
            </span>
            <span slot="userType" slot-scope="text, record">
              {{record.userType|filterUserType}}
            </span>
            <span slot="operation" slot-scope="text, record" v-if="record.id !== 1">
              <a v-if="record.userType == 2" @click="handleTz(record)" v-hasPermi="['tzgs']">
                <a-icon type="edit" />
                {{ $t('调增公司') }}
              </a>
              <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['system:user:edit']">
                <a-icon type="edit" />
                {{ $t('修改') }}
              </a>
              <a-divider type="vertical" v-hasPermi="['system:user:remove']" />
              <a @click="handleDelete(record)" v-hasPermi="['system:user:remove']">
                <a-icon type="delete" />
                {{ $t('删除') }}
              </a>
              <a-divider type="vertical" v-hasPermi="['system:user:resetPwd', 'system:user:edit']" />
              <a-dropdown v-hasPermi="['system:user:resetPwd', 'system:user:edit']">
                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                  <a-icon type="double-right" />
                  {{ $t('更多') }}
                </a>
                <a-menu slot="overlay">
                  <a-menu-item v-hasPermi="['system:user:resetPwd']">
                    <a @click="$refs.resetPassword.handleResetPwd(record)">
                      <a-icon type="key" />
                      {{ $t('重置密码') }}
                    </a>
                  </a-menu-item>
                  <!-- <a-menu-item v-hasPermi="['system:user:edit']">
                    <a @click="$refs.authRole.handleAuthRole(record)">
                      <a-icon type="check-circle" />
                      {{ $t('分配角色') }}
                    </a>
                  </a-menu-item> -->
                </a-menu>
              </a-dropdown>
            </span>
          </a-table>
          <!-- 分页 -->
          <a-pagination
            class="ant-table-pagination"
            show-size-changer
            show-quick-jumper
            :current="queryParam.pageNum"
            :total="total"
            :page-size="queryParam.pageSize"
            :showTotal="total => `${$t('共')} ${total} ${$t('条')}`"
            @showSizeChange="onShowSizeChange"
            @change="changeSize" />
        </a-col>
      </a-row>
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { pageUser, delUser, changeUserStatus, projectTreeSelect } from '@/api/system/user'
import AuthRole from './modules/AuthRole'
import ResetPassword from './modules/ResetPassword'
import CreateForm from './modules/CreateForm'
import ImportExcel from './modules/ImportExcel'
import DeptTree from './modules/DeptTree'
import { tableMixin } from '@/store/table-mixin'
import TzCreateForm from './modules/TzCreateForm.vue'

export default {
  name: 'User',
  components: {
    AuthRole,
    ResetPassword,
    CreateForm,
    ImportExcel,
    DeptTree,
    TzCreateForm
  },
  mixins: [tableMixin],
  dicts: ['sys_normal_disable', 'sys_user_sex'],
  filters: {
    filterUserType(value) {
      const arr = [
        {value: 1, label: '管理员'},
        {value: 2, label: '商户'},
        {value: 3, label: '补货员'},
        {value: 4, label: '客服'},
        {value: 5, label: '公司'},
      ]
      const filterArr = arr.filter(item => item.value == value)
      return filterArr.length?filterArr[0].label:''
    }
  },
  data() {
    return {
      tzVisible: false,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 部门树选项
      deptOptions: [{
        id: 0,
        label: '',
        children: []
      }],
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        userName: undefined,
        phoneNumber: undefined,
        status: undefined,
        deptId: undefined
      },
      columns: [
        // {
        //   title: this.$t('用户编号'),
        //   dataIndex: 'id',
        //   align: 'center'
        // },
        {
          title: this.$t('用户名'),
          dataIndex: 'userName',
          align: 'center'
        },
        {
          title: this.$t('手机号'),
          dataIndex: 'phoneNumber',
          align: 'center'
        },
        {
          title: this.$t('用户昵称'),
          dataIndex: 'nickName',
          align: 'center'
        },
        {
          title: this.$t('用户类型'),
          dataIndex: 'userType',
          align: 'center',
          scopedSlots: {customRender: 'userType'}
        },
        {
          title: this.$t('所属上级'),
          dataIndex: 'fname',
          align: 'center',
        },
        // {
        //   title: '部门',
        //   dataIndex: 'dept.deptName',
        //   scopedSlots: { customRender: 'dept.deptName' },
        //   align: 'center'
        // },
        // {
        //   title: this.$t('项目'),
        //   dataIndex: 'projectNames',
        //   // scopedSlots: { customRender: 'dept.deptName' },
        //   align: 'center'
        // },
        {
          title: this.$t('状态'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: this.$t('创建时间'),
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: this.$t('操作'),
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          width: '300px'
        }
      ]
    }
  },
  created() {
    this.getList()
    this.getDeptTree()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    handleTz(row) {
      this.tzVisible = true
      this.$nextTick(() => {
        this.$refs.tzForm.init(row)
      })
    },
    getTreeselect() { },
    /** 查询用户列表 */
    getList() {
      this.loading = true
      pageUser(this.addDateRange(this.queryParam, this.dateRange)).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      }
      )
    },
    /** 查询部门下拉树结构 */
    getDeptTree() {
      projectTreeSelect().then(response => {
        this.deptOptions = response.data
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        userName: undefined,
        phoneNumber: undefined,
        status: undefined,
        deptId: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
      this.setTableScrollY()
    },
    clickDeptNode(deptId) {
      // this.queryParam.deptId = deptId
      this.queryParam.projectId = deptId
      this.handleQuery()
    },
    /* 用户状态修改 status 0开启 1关闭 */
    confirmHandleStatus(row) {
      const oldStatus = row.status
      const text = row.status === 0 ? this.$t('启用') : this.$t('关闭')
      const status = row.status === 1 ? 0 : 1
      changeUserStatus(row.id, status).then((res) => {
        if (res.success) {
          row.status = status
          this.$message.success(text + this.$t('成功'), 3)
        } else {
          row.status = oldStatus
          this.$message.error(`${text}${this.$t('失败')}, ${res.message}`, 3)
        }
      }).catch(() => {
        this.$message.error(text + this.$t('异常'), 3)
      })
    },
    cancelHandleStatus(row) {
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const userIds = row.id || this.ids
      this.$confirm({
        title: this.$t('确认删除所选中数据?'),
        content: this.$t('当前选中编号为') + userIds + this.$t('的数据'),
        onOk() {
          return delUser(userIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                that.$t('删除成功'),
                3
              )
            })
        },
        onCancel() { }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: this.$t('是否确认导出?'),
        content: this.$t('此操作将导出当前条件下所有数据而非选中数据'),
        onOk() {
          that.download('system/user/export', {
            ...that.queryParam
          }, `user_${new Date().getTime()}.xlsx`)
        },
        onCancel() { }
      })
    }
  }
}
</script>
